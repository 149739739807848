/* eslint-disable quotes */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import EpisodeList from "./EpisodeLists";

import DashIcon from "../icons/Dash";

const EpisodesSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulEpisodesPage {
        heading
        bannerSection {
          title
          buttonName
          buttonSlug
          description {
            description
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const { contentfulEpisodesPage } = data || {};
  const bannerPost = contentfulEpisodesPage.bannerSection;

  return (
    <section className="">
      <div className="relative md:py-0 py-24 flex content-center items-center md:min-h-[30vh] lg:min-h-[65vh] min:h-[75vh] mb-[-5.4375] ">
        <div className="absolute h-full flex top-0 w-full bg-center bg-cover bg-gradient-to-br from-black via-gray-900 to-transparent">
          <GatsbyImage
            style={{ height: "auto" }}
            image={bannerPost?.image.gatsbyImageData}
            alt={bannerPost.title}
            className="w-full h-auto object-cover"
          />
        </div>
        <div className="relative md:px-[2rem] px-[1.5rem] lg:px-[2.5rem] w-full">
          <div className="flex flex-col justify-center items-center md:items-start space-y-5">
            <div className="w-full">
              <div className="md:pr-12 md:w-full lg:w-[65%]">
                <h1 className="text-heading text-2xl md:text-[32px] text-center md:text-left font-semibold lg:text-6xl mb-[0.5]">
                  {bannerPost?.title}
                </h1>
                <h2 className="lg:w-[60%] w-full mt-2 md:text-[22px] text-center md:text-left text-[18px] leading-6 md:leading-6 text-heading mb-4">
                  {bannerPost.description?.description}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:px-[1.5rem] lg:px-[1.125rem] px-[1.5rem]">
        <div className="lg:py-[40px] py-[20px]">
          <div className="bg-background shadow-lg md:px-[1.5rem]">
            <div className="mb-[20px] block">
              <h1 className="text-heading font-normal relative md:text-title1 mb-[2px]">
                {data.contentfulEpisodesPage.heading}
              </h1>
              <DashIcon />
            </div>
            <EpisodeList />
          </div>
        </div>
      </div>
      <hr className="border-0 w-[90%] h-[1px] mb-5 opacity-50 bg-gradient-to-r from-transparent via-border-color to-transparent" />
    </section>
  );
};
export default EpisodesSection;
