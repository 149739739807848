/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from 'react';

import Seo from '../components/Seo';
import EpisodesLayout from '../components/EpisodesLayout';
import EpisodesSection from '../components/EpisodesSection';

const EpisodesPage = () => (
  <div className="bg-background">
    <div className="mx-auto max-w-[85.375rem] bg-background shadow-xl shadow-[#000000ad]">
      <EpisodesLayout>
        <Seo title="Episodes" />
        <EpisodesSection />
      </EpisodesLayout>
    </div>
  </div>
);

export default EpisodesPage;
